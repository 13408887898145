import React from 'react'
import './JoinOurTeam.css'
import { Col, Container, Row } from 'react-bootstrap'
import joinOurTeamImage from '../../assests/career/joinOurTeam.svg'

const JoinOurTeam = () => {
  return (
    <>
    <Container>
        <Row>
            <Col sm={12} md={12} lg={6}>
            <h2 style={{fontSize: '26px', marginBottom: '25px'}}>Join <span className='ourTeamText'>Our Team</span> of Innovators and Creators</h2>
            <p style={{fontSize: '19px', marginBottom: '25px'}}>At Praathee Technologies, we believe in the power of diversity, creativity, and collaboration. With our team comprised of brilliant minds from diverse backgrounds, we work together to solve,</p>
            
            <ul style={{fontSize: '19px'}}>
                <li>Challenges Complex</li>
                <li>Drive Meaningful Impact And Much More</li>
            </ul>
            </Col>

            <Col md={12} lg={6} className='d-none d-md-block'>
            <img src={joinOurTeamImage} style={{width: '100%', height: '70%'}}  alt="join our team" />
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default JoinOurTeam