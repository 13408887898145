import React from "react";
import "./WhyChoose.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const WhyChoose = () => {
  const whyChooseP = `With website-friendly resources and authentic solutions, we stand the best in all areas of expertise. So,
     whatever your needs are, you can reach out to us and get benefits effectively.`;

  return (
    <>
      <Container>
        <div className="whyChooseText">
          <h2 style={{ color: "#0073CF", marginBottom: "25px", fontWeight: 500, fontSize: '26px' }}>
            WHY SHOULD YOU CHOOE US?
          </h2>
          <p style={{ fontWeight: 300, fontSize: '19px'}} className="whyChooseP">{whyChooseP}</p>
        </div>
        <Row style={{gap: '21px'}} className="g-2 align-items-center justify-content-center">
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#6F71EE', backgroundColor: '#E3EBFF', fontSize: '19px'}}>Distinguished Website Solutions</p>
          </Col>
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#FF008A', backgroundColor: '#FFE1FA', fontSize: '19px'}}>Optimization and Evolution</p>
          </Col>
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#417762', backgroundColor: '#DEFEE7', fontSize: '19px'}}>Cost-Friendly Price</p>
          </Col>
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#EF3939', backgroundColor: '#FFE4E4', fontSize: '19px', paddingTop: '18px', paddingBottom: '18px', width: '160px'}}>Timely Services</p>
          </Col>
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#9D5EA8', backgroundColor: '#FAE3FF', fontSize: '19px', height: '67px', paddingTop: '16px', width: '160px'}}>Integration</p>
          </Col>
          <Col lg={2} style={{width: '180px'}} className="d-flex justify-content-center">
            <p className="textCol1" style={{ color: '#F08733', backgroundColor: '#FEEFDC', fontSize: '19px'}}>100% Quality Assurance</p>
          </Col>
        </Row>

        <div>
          <p style={{fontWeight: 500, fontSize: '26px'}} className="endText">
            Want a web application that marks your business growth as a
            significant one?{" "}
            <Link to='/contact' style={{ color: "rgb(60, 112, 232)", textDecoration: 'none' }}>Connect Now!</Link>
          </p>
        </div>
      </Container>
    </>
  );
};

export default WhyChoose;
