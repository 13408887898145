import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import getInTouchImage from '../../../assests/services/CloudAppDevelopment/getInTouchImage.svg'
import { FaArrowRight } from "react-icons/fa";

const GetInTouch = () => {
  return (
    <>
    <Container>
        <p style={{fontWeight: 300, fontSize: '16px', textAlign: 'center'}}>If you are a startup or software development firm that needs efficient “cloud” solutions, get in touch with the best software development service provider Praathee Technologies. With their potent service and professionals, build excellent cloud applications that help, accelerate changes in your business through digital transformation seamlessly.</p>
        <p style={{fontWeight: 500, fontSize: '20px', color: '#0073CF', textAlign: 'center', marginTop: '60px'}}>Get in Touch With Experts Today!</p>
    
        <Row style={{marginTop: '80px'}}>
            <Col>
            <img src={getInTouchImage} alt="" />
            </Col>

            <Col>
            <p style={{fontWeight: 500, fontSize: '18px'}}><span style={{color: '#0073CF'}}>Praathee Technologies</span> - You Ultimate Platform to Avail Endless Digital Possibilities</p>
            <p style={{fontWeight: 300, fontSize: '15px', lineHeight: '20px'}}>At Praathee, We are well-versed in app developments that meet digital transformation needs accordingly. With the digitization upsurge, the need for agile and simple solutions like “Cloud app development” is efficient. While developing cloud solutions for app development is challenging with the right team the benefits you reap are tremendous. So, when you get your services from us, the benefits that you avail are,</p>
            <ul style={{listStyle: 'none'}}>
                <li><FaArrowRight style={{color: '#008EFF', marginRight: '10px'}}/>Quick Responses</li>
                <li><FaArrowRight style={{color: '#008EFF', marginRight: '10px'}}/>Easy Access and Scalability</li>
                <li><FaArrowRight style={{color: '#008EFF', marginRight: '10px'}}/>Budget-friendly Prices</li>
                <li><FaArrowRight style={{color: '#008EFF', marginRight: '10px'}}/>Automatic Updations</li>
                <li><FaArrowRight style={{color: '#008EFF', marginRight: '10px'}}/>Top-Notch Maintenance</li>
            </ul>
            </Col>
        </Row>
    
    </Container>
    </>
  )
}

export default GetInTouch