import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const MobileAppServices = () => {
  const text1 = `Android mobile
     app development`;

  const text2 = `IOS mobile app
      development`;

  const text3 = `User-centric design
      and development`;

  const text4 = `Native and cross-
     platform development`;

  const text5 = `App Maintenance
      and Support`;

  return (
    <>
      <Container style={{ marginTop: "80px" }}>
        <h2
          style={{
            fontWeight: 500,
            fontSize: "26px",
            color: "#0073CF",
            textAlign: "center",
            marginBottom: '25px'
          }}
        >
          Our Mobile App Development Services
        </h2>
        <p style={{ fontWeight: 300, fontSize: "19px", marginBottom: "25px" }}>
          Our goal is to provide versatile services that cater to the diverse
          needs of mobile application developers. With solutions tailored by our
          expert team, we ensure your requirements are met with precision and
          care. From initial concept to final deployment, we focus on delivering
          high-quality results that align with your vision.
        </p>
        <Row style={{justifyContent: 'center'}}>
          <Col md={12} style={{ textAlign: "center", width: '260px' }}>
            <p
              style={{
                paddingTop: "20px",
                borderRadius: "10px",
                paddingBottom: "20px",
                whiteSpace: "pre-line",
                color: "#6F71EE",
                fontSize: "19px",
                fontWeight: 400,
                background: "#E3EBFF",
              }}
            >
              {text1}
            </p>
          </Col>

          <Col md={12} style={{ textAlign: "center", width: '260px' }}>
            <p
              style={{
                paddingTop: "20px",
                borderRadius: "10px",
                paddingBottom: "20px",
                whiteSpace: "pre-line",
                color: "#FF008A",
                fontSize: "19px",
                fontWeight: 400,
                background: "#FFE1FA",
              }}
            >
              {text2}
            </p>
          </Col>

          <Col md={12} style={{ textAlign: "center", width: '260px' }}>
            <p
              style={{
                paddingTop: "20px",
                borderRadius: "10px",
                paddingBottom: "20px",
                whiteSpace: "pre-line",
                color: "#417762",
                fontSize: "19px",
                fontWeight: 400,
                background: "#DEFEE7",
              }}
            >
              {text3}
            </p>
          </Col>

          <Col md={12} style={{ textAlign: "center", width: '260px' }}>
            <p
              style={{
                paddingTop: "20px",
                borderRadius: "10px",
                paddingBottom: "20px",
                whiteSpace: "pre-line",
                color: "#EF3939",
                fontSize: "19px",
                fontWeight: 400,
                background: "#FFE4E4",
              }}
            >
              {text4}
            </p>
          </Col>

          <Col md={12}  style={{ textAlign: "center", width: '260px' }}>
            <p
              style={{
                paddingTop: "20px",
                borderRadius: "10px",
                paddingBottom: "20px",
                whiteSpace: "pre-line",
                color: "#F08733",
                fontSize: "19px",
                fontWeight: 400,
                background: "#FEEFDC",
              }}
            >
              {text5}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MobileAppServices;
