import React from "react";
import "./AboutUs.css";
import { Col, Container, Row } from "react-bootstrap";
import aboutUsImage from '../../assests/home/aboutusImage.svg'

const AboutUs = () => {
  return (
    <Container>
      <Row>
        <Col md={12} lg={6} className="d-none d-md-block" >
          <img src={aboutUsImage} className="text-start" alt="software development company" style={{ width: '90%'}} />
        </Col>

        <Col sm={12} xs={12} md={12} lg={6}>
          <div>
            <h3 className="aboutStyle">ABOUT US</h3>
            <div style={{marginBottom: '25px'}}>
              <h1 style={{fontSize: '26px'}}>A PROFICIENT SOFTWARE DEVELOPMENT COMPANY</h1>
            </div>
            <div style={{ marginBottom: '25px', color: 'rgb(60, 112, 232)', fontSize: '19px'}}>
                <p>Praathee Technologies marks its milestone as one of the most searched top software development companies.</p>
            </div>
            <div className="aboutList">
                <ul>
                    <li>With 7+ years of experience, we excel in software solution expertise. Additionally we provide various services in a timely and budget-friendly manner.</li>
                    <li>As the best software development company, we offer versatile and seamless services.</li>
                    <li>So, if you are a startup or looking for business looking for remarkable software development services, connect with us and achieve results immediately.</li>
                </ul>
            </div>

          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
