import React from "react";
import "./CompanyBest.css";
import { Col, Container, Row } from "react-bootstrap";
import companyBestImg from '../../assests/aboutUs/companyBest.svg'

const CompanyBest = () => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={12} md={12} lg={6}>
            <p style={{fontSize: '19px'}}>
              Our company is the best because our expert team is skilled in
              offering simple yet unique solutions. As a solution provider, we
              understand the competition and tools that are constantly evolving.
              So, accordingly, we stay updated and serve only the finest of
              anything that you ask for.
            </p>

            <ul className="compul" style={{fontSize: '19px', marginBottom: '25px'}}>
              <li className="liborder">
                Your success is our priority. With the best-picked talents in
                our professional spectrum, your work will be smooth and 100%
                reliable. From the start to the end, the software development
                services that you need will be researched and delivered with the
                utmost professionalism. No hassles will be found here.
              </li>
            </ul>

            <p style={{fontSize: '19px'}}>
              Innovate, transform, and revolutionize your business goals and IT
              developments with our professional and versatile IT Management
              services effortlessly.
            </p>
          </Col >

          <Col className="imgres d-none d-md-block text-end" md={12} lg={6}>
            <img src={companyBestImg} className="companyBestImg" alt="software solution" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanyBest;
