import React, { useEffect, useState } from 'react'
import { Container} from 'react-bootstrap'
import './Clients.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RelianceLogo from '../../assests/Images/ClientLogos/reliance_logo.jpg'
import AnsLogo from '../../assests/Images/ClientLogos/ans.jpeg'
import YourFarmLogo from '../../assests/Images/ClientLogos/yf.png'
import EonianLogo from '../../assests/Images/ClientLogos/EonianLogo.webp'
import NCMLogo from '../../assests/Images/ClientLogos/LogNCM12.png'

const Clients = () => {


    const [slidesToShow, setSlidesToShow] = useState(4)

    const handleResize = () => {
      if(window.innerWidth < 481){
        setSlidesToShow(1)
      }else if (window.innerWidth < 767){
        setSlidesToShow(2)
      }else if(window.innerWidth < 991){
        setSlidesToShow(3)
      }else{
        setSlidesToShow(4)
      }
    }

    useEffect(() => {
      handleResize()


      window.addEventListener('resize', handleResize)
      handleResize()

      return() => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])
    


    var settings = {
        dots:false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow, 
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 1000,
        initialSlide: 0,
      };

  return (
    <>
    <Container style={{marginTop: 20, marginBottom: 60}}>
        {/* <h1 className='clientTitle'>Our Esteemed Clients</h1> */}
        <Slider {...settings}>
      <div className='sliderItem1'>
        <img src={RelianceLogo} alt="relianceLogo" />
      </div>
      <div className='sliderItem'>
        <img src={AnsLogo} alt="AnsLogo" />
      </div>
      <div className='sliderItem'>
        <img src={YourFarmLogo} alt="YourFarmLogo" />
      </div>
      <div className='sliderItem'>
        <img src={EonianLogo} alt="EonianLogo" />
      </div>
      <div className='sliderItem'>
      <img src={NCMLogo} alt="NCMLogo" />
      </div>

    </Slider>
    </Container>


    </>
  )
}

export default Clients