import React from 'react'
import CareerBanner from './Banner/CareerBanner'
import TopNav from '../Common/Navbar/TopNav'
import JoinOurTeam from './JoinOurTeam/JoinOurTeam'
import CareerOpportunities from './CareerOpportunities/CareerOpportunities'
import BenefitsOfJoining from './BenefitsOfJoining/BenefitsOfJoining'
import Footer from '../Common/Footer/Footer'
import LetsGrow from '../Home/LetsGrow/LetsGrow'
import CareerForm from './CareerForm/CareerForm'
import { Helmet } from 'react-helmet'

const CareerPage = () => {
  return (
    <>
    <div>
      <Helmet>
      <meta
            title="Career"
            content="Join Praathee Media: Explore Exciting Career Opportunities"
          />
          <meta
            name="description"
            content="Explore rewarding career opportunities at Praathee Media, a leading software solution provider. Join our dynamic team and unleash your potential."
          />
          <meta
            property="og:title"
            content="Shape Your Future with Praathee Media: Explore Job Openings"
          />
          <meta
            property="og:description"
            content="Join Praathee Media and embark on a journey of growth and success. Explore our diverse career opportunities and find your perfect fit."
          />
      </Helmet>
    </div>
    <TopNav/>
    <CareerBanner/>
    <JoinOurTeam/>
    <CareerOpportunities/>
    <BenefitsOfJoining/>
    <CareerForm/>
    <h3 style={{ textAlign: 'center', marginBottom: '100px', marginTop: '100px'}}>Build <span style={{ color: 'rgb(60, 112, 232)'}}>Your Career</span> With Us - Connect Today!</h3>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default CareerPage