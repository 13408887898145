import React from "react";

import contactBanner from "../../assests/Banner/ContactBanner.svg";
import { Container } from "react-bootstrap";

const ContactBanner = () => {
  return (
    <>
      <div className="banner">
        <img src={contactBanner} alt="contact" className="img-fluid banner-image" />
        <div className="banner-text">
          <h1>CONTACT US</h1>
          <p style={{ fontSize: "30px" }}>
            Turn Your Software Development Project Into Reality - Gain Best
            Solutions Now!
          </p>
        </div>
      </div>

      <Container style={{ textAlign: "center" }}>
        <p style={{ fontSize: "26px" }}>
          You are just a step away from taking your business to the next level.
          With our expert team, you can connect and get answers quickly.
        </p>
        <span style={{ fontSize: "19px" }}>
          <strong>To Enquire :</strong> Send a mail via{" "}
          <strong>
            <a href={`mailto:support@praathee.com`}>support@praathee.com</a>
          </strong>
        </span>
      </Container>
    </>
  );
};

export default ContactBanner;
