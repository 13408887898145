import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import whyMobileImage from "../../../assests/services/MobileAppDevelopment/whyMobileAppImage.svg";
// import arrowImage from '../../../assests/services/MobileAppDevelopment/arrowVector.svg'
import { FaArrowRight } from "react-icons/fa";

const WhyMobileApp = () => {
  const text1 = `In recent times, the use of mobile phones and the need for mobile applications
     are growing constantly. With mobile phones dominating the world, designing and
      developing mobile applications is the best way for businesses to earn success and
       reputation rapidly. For instance, When you get excellent mobile application designs
        and solutions from us, you can acquire the following advantages.`;
  return (
    <>
      <Container style={{marginTop: '60px'}}>
        <Row>
          <Col lg={6} md={12}>
            <h2 style={{ fontWeight: 500, fontSize: "26px", color: '#0073CF' }}>
              Why Mobile App Development Is Important?
            </h2>
            <p
              style={{
                
                fontWeight: 300,
                fontSize: "19px",
                // lineHeight: "20px",
                fontWeight: 300
              }}
            >
              {text1}
            </p>

            <ul style={{listStyle: 'none'}}>
                <li style={{fontWeight: 300, fontSize: '19px'}}><FaArrowRight style={{color: '008EFF', marginRight: '5px'}} />Increases your customer reach</li>
                <li style={{fontWeight: 300, fontSize: '19px'}}><FaArrowRight style={{color: '008EFF', marginRight: '5px'}}/>Improves your customer engagement</li>
                <li style={{fontWeight: 300, fontSize: '19px'}}><FaArrowRight style={{color: '008EFF', marginRight: '5px'}}/>Elevates your brand awareness and visibility</li>
                <li style={{fontWeight: 300, fontSize: '19px'}}><FaArrowRight style={{color: '008EFF', marginRight: '5px'}}/>Upgrades your brand image</li>
                <li style={{fontWeight: 300, fontSize: '19px'}}><FaArrowRight style={{color: '008EFF', marginRight: '5px'}}/>Boosts your retention rate</li>
            </ul>
          </Col>

          <Col lg={6} md={12} className="d-none d-md-block">
            <img src={whyMobileImage} alt="mobile app development service" style={{width: '100%', height: '100%'}} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhyMobileApp;
