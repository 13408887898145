import React from 'react'
import MobileBanner from './Banner/Banner'
import TopNav from '../../Common/Navbar/TopNav'
import BestMobileApp from './BestMobileApp/BestMobileApp'
import MobileAppServices from './MobileAppServices/MobileAppServices'
import WhyMobileApp from './WhyMobileApp/WhyMobileApp'
import WhatMakes from './WhatMakesUs/WhatMakes'
import LetsGrow from '../../Home/LetsGrow/LetsGrow'
import Footer from '../../Common/Footer/Footer'
import AndroidAppDev from './AndroidAppDev/AndroidAppDev'
import { Helmet } from 'react-helmet'

const MobileAppDev = () => {
  return (
    <>
    <div>
      <Helmet>
      <meta
            title="Mobile App Development"
            content="Top Mobile App Development Service |  Build Your Dream App "
          />
          <meta
            name="description"
            content="Boost your business with Praathee's mobile app development. Specializing in custom mobile solutions, we deliver apps that are innovative, user-friendly, and goal-aligned."
          />
          <meta
            property="og:title"
            content="Transforming Ideas Into Apps with Our Mobile App Developers"
          />
          <meta
            property="og:description"
            content="Praathee Media is the leading mobile app development company. From ideation to launch, our experts craft custom, cutting-edge apps tailored to your needs."
          />
          <meta
            name="keywords"
            content="mobile app development, android app development, ios app development, hybrid app development, app developers, app development, mobile app development, mobile application development"
          />
      </Helmet>
    </div>
    <TopNav/>
    <MobileBanner/>
    <BestMobileApp/>
    <MobileAppServices/>
    <AndroidAppDev/>
    <WhyMobileApp/>
    <WhatMakes/>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default MobileAppDev