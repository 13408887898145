import React from "react";
import TopNav from "../Common/Navbar/TopNav";
import Empower from "./Empowering/Empower";
import Clients from "./Clients/Clients";
import AboutUs from "../Home/AboutUs/AboutUs";
import Circles from "../Home/Circles/Circles";
import OurServices from "./OurServices/OurServices";
import WhatMakes from "./WhatMakes/WhatMakes";
import ClientReviews from "../Home/ClientReviews/ClientReviews";
// import OurBlogs from './OurBlogs/OurBlogs'
import LetsGrow from "./LetsGrow/LetsGrow";
import Footer from "../Common/Footer/Footer";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <>
      <div>
        <Helmet>
          <meta
            title="Welcome to Praathee Technologies"
            content="Leading Software Development Company for Innovative Solutions"
          />
          <meta
            name="description"
            content="Discover Praathee Technologies, your one-stop destination for software development excellence. Experts in mobile, web, cloud, blockchain dev & software products."
          />
          <meta
            property="og:title"
            content="Top Software Development Services to Elevate Your Business "
          />
          <meta
            property="og:description"
            content="Welcome to Praathee Technologies, where we specialize in mobile, web, cloud, and blockchain development, along with software product offerings."
          />
          <meta
            name="keywords"
            content="software developmet, mobile app development , web app development, coimbatore, software developer, blockchain devlopment, cloud app development, software company, it company,"
          />
        </Helmet>
      </div>

      <TopNav />
      <Empower />
      <Clients />
      <AboutUs />
      <Circles />
      <OurServices />
      <WhatMakes />
      <ClientReviews />
      {/* <OurBlogs/> */}
      <LetsGrow />
      <Footer />
    </>
  );
};

export default HomePage;
