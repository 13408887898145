import React, { useState } from "react";
import "./ContactForm.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import contactFormImage from "../../assests/contact/contactFormImage.svg";
import InputGroup from "react-bootstrap/InputGroup";

const ContactForm = () => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <Container style={{ marginBottom: "20px" }}>
        <Row>
          <Col md={12} lg={6} className="d-none d-md-block">
            <img
              src={contactFormImage}
              style={{width: '80%', height: '80%'}}
              alt="contact form"
            />
          </Col>

          <Col md={12} lg={6} sm={12}>
            <h2 style={{ color: "rgb(60, 112, 232)" }}>Get in Touch</h2>
            <p style={{fontSize: '19px'}}>
              Don't Hesitate To{" "}
              <span style={{ color: "rgb(60, 112, 232)" }}>Contact Us <br /></span> Say
              Hello or Message
            </p>
            <Form style={{ marginTop: "40px" }}>
              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Full Name</Form.Label>
                    <Form.Control type="email" placeholder="Enter Name" />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Phone Number</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Phone Number"
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Company Name</Form.Label>
                    <Form.Control type="email" placeholder="Enter Company" />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Country</Form.Label>
                    <Form.Control type="email" placeholder="Enter Country" />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Attachment</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Description</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Message"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button className="formBtn" variant="primary" type="submit">
                Send Message
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactForm;
