import React from 'react'
// import bannerImage from '../../assests/Banner/spider-web.jpg'
import './AboutBanner.css'
import aboutUsBannerImage from '../../assests/Banner/aboutUsBanner.svg'

const AboutBanner = () => {

  const text1 =`Flexibility and Software Consultations at Its Best`
  return (
    <>
    <div className="banner">
      <img src={aboutUsBannerImage} alt="about us" className='banner-image' />
      <div className="banner-text">
        <h2 style={{fontSize: '44px'}}>ABOUT US</h2>
        {/* <p>Flexibility and Software Consultations at Its Best</p> */}
        <p style={{whiteSpace: 'pre-line', fontSize: '30px'}}>{text1}</p>
      </div>
    </div>
    </>
  )
}

export default AboutBanner