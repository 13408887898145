import React from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import './PrPower.css'

const PrPower = () => {



    const cardData = [
        {
          id: 1,
          title: "SaaS",
          description:
            "Leveraging our SaaS services, you can create entirely new or retain your existing software efficiently without any hassle. This can be utterly beneficial because our experts use advanced cloud technology like AI/ML, AR/VR, and blockchain. So, this way, your SaaS management for all SaaS implementations will be scalable and come packed with an effective user interface.",
        //   img: customAppImage,
        },
        {
          id: 2,
          title: "PaaS",
          description:
            "PaaS, often referred to by business enterprises as open-source projects, is one of the most used cloud solutions that help service providers build, develop, and update applications securely and effectively. As we understand the importance of PaaS, we offer tailored solutions for custom platforms and drive team development significantly.",
            // img: ecommerceImage,
        },
        {
          id: 3,
          title: "IaaS",
          description:
            "With the best-developed IaaS cloud-computing service, eliminate the responsibility of managing and maintaining infrastructure. Use this advanced and simplified solution thus paving the way for aiding infrastructure services such as managing bills, logging, storage resiliency, and monitoring to boost compliance and security.",
            // img: uiuxImage,
        },
        {
          id: 4,
          title: "Customization",
          description:
            "We work with great agility and are determined to create or develop cloud-based applications according to the client's preferences. Besides that, we are also equipped with first-class technology and an expert team that understands your cloud needs exclusively. So, unleash your imagination and get accelerated cloud-native applications methodically.",
            // img: testingImage,
        },
        {
          id: 5,
          title: "Reliability",
          description:
            "We understand your needs and create/develop cloud software solutions with hi-tech resources with our team of experts exponentially. Also, we deliver the applications on time with prices that are budget-friendly. So, with us, you get 100% guaranteed results with precision and stability.",
            // img: maintenanceImage,
        },
        {
          id: 6,
          title: "Experience",
          description:
            "Over the years, our team of skilled software developers has created and retained many custom based cloud applications that are best in the platform. Also, they stand the test of time with excellent results. Hence, uplift your expertise with our cloud app solutions and shine.",
            // img: seamlessImage,
        },
      ];
  return (
    <>
    <Container style={{marginTop: '80px'}}>
        <p style={{ fontWeight: 500, fontSize: '20px', color: '#0073CF', textAlign: 'center'}}>The Power of Praathee’s Distinguished Cloud App Services</p>
        <p style={{ fontWeight: 300, fontSize: '16px', textAlign: 'center', lineHeight: '20.83px', marginBottom: '80px'}}>As premium cloud app developers, we aim to provide exceptional cloud services that meet the needs of every expert with agility. So, the types of cloud solutions that we offer are as follows.</p>



        <div>
        
        <Row className="powerCard1">
          {cardData.slice(0, 3).map((card) => (
            <Card style={{ width: "378px", marginBottom: '60px', height: '306px' }} key={card.id}>
              
              <Card.Body>
                <Card.Title style={{fontSize: '20px', fontWeight: 500, textAlign: 'center', padding: '10px'}}>{card.title}</Card.Title>
                
                <Card.Text style={{fontSize: '15px', fontWeight: 300, lineHeight: '24px', textAlign: 'center'}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row>

        <Row className="powerCard2">
          {cardData.slice(3, 6).map((card) => (
            <Card style={{ width: "378px", marginBottom: '60px', height: '306px', borderColor: '#D7D7D7' }} key={card.id}>
              
              <Card.Body>
                <Card.Title style={{fontSize: '20px', fontWeight: 500, textAlign: 'center', padding: '10px'}}>{card.title}</Card.Title>
                
                <Card.Text style={{fontSize: '15px', fontWeight: 300, lineHeight: '24px', textAlign: 'center'}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row>
      </div>


      <p style={{fontWeight: 500, fontSize: '20px',textAlign: 'center', marginTop: '40px', marginBottom: '80px' }}>Hire Our Team Today and Get <span style={{color: '#0073CF'}}>Expert Cloud Solutions Today!</span></p>
    </Container>



    </>
  )
}

export default PrPower