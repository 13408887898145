import React from "react";
import TopNav from "../Common/Navbar/TopNav";
import Footer from "../Common/Footer/Footer";
import { Container } from "react-bootstrap";
import LetsGrow from "../Home/LetsGrow/LetsGrow";

const TermsandConditions = () => {
  return (
    <section>
        <Container>
      <TopNav />
      <div className="terms mt-40 ps-50">
        <div className="row">
          <div className="col-lg-12">
            <h1 className="">Terms and Conditions</h1>
            <div className="mb-5">
              <p>
                Welcome to Praathee Media Private Limited! By accessing or using
                our website, you agree to be bound by the following Terms and
                Conditions. Please read them carefully. If you do not agree with
                these Terms, you should refrain from using our services.
              </p>
            </div>

            <h1>Introduction:</h1>

            <div className="mb-5">
              <p>
                These Terms and Conditions govern your use of our website,
                including all services and products offered through the website.
                By accessing or using the website, you agree to comply with
                these Terms.
              </p>
            </div>

            <h1>Privacy Policy:</h1>
            <div className="mb-5">
              <p>
                Your use of our website is also governed by our{" "}
                <a href="/privacy-policy">Privacy Policy</a>, which outlines how
                we collect, use, and protect your personal information. By using
                our website, you consent to the collection and use of your
                information as described in our{" "}
                <a href="/privacy-policy">Privacy Policy</a>.
              </p>
            </div>

            <h1>Use of the Website:</h1>
            <div className="mb-5">
              <p>
                You must use the website in accordance with all applicable laws
                and regulations.
              </p>
              <p>
                You agree not to use the website for any illegal or unauthorized
                purposes, including but not limited to posting harmful,
                fraudulent, or misleading information.
              </p>
            </div>

            <h1>Content and Intellectual Property:</h1>
            <div className="mb-5">
              <p>
                All content on the website, including text, images, graphics,
                logos, and other materials, is owned or licensed by Praathee
                Media Private Limited and is protected by intellectual property
                laws.
              </p>
              <p>
                You may not copy, modify, distribute, or use any content from
                the website without obtaining prior written consent from us.
              </p>
            </div>

            <h1>Purchases and Payments:</h1>
            <div className="mb-5">
              <p>
                If you purchase any products or services through our website,
                you agree to pay the full amount as stated at the time of
                purchase.
              </p>
              <p>
                All payments are processed through secure third-party payment
                gateways. We do not store your payment information.
              </p>
            </div>

            <h1>Limitation of Liability:</h1>
            <div className="mb-5">
              <p>
                Praathee Media Private Limited is not liable for any direct,
                indirect, incidental, or consequential damages arising from your
                use of the website or inability to access the website.
              </p>
              <p>
                We make no warranties or representations about the accuracy,
                reliability, or completeness of the content on the website.
              </p>
            </div>

            <h1>Links to Third-Party Websites:</h1>
            <div className="mb-5">
              <p>
                Our website may contain links to third-party websites for your
                convenience. We do not endorse, control, or assume
                responsibility for the content, privacy policies, or practices
                of these websites.
              </p>
            </div>

            <h1>Cookies:</h1>
            <div className="mb-5">
              <p>
                We use cookies to enhance your experience on our website. By
                using our website, you consent to the use of cookies as
                described in our Privacy Policy.
              </p>
            </div>

            <h1>Changes to Terms and Conditions:</h1>
            <div className="mb-5">
              <p>
                We reserve the right to modify these Terms and Conditions at any
                time without prior notice. Changes will be effective immediately
                upon posting on this page.
              </p>
              <p>
                Your continued use of the website after any modifications
                signifies your acceptance of the updated Terms.
              </p>
            </div>

            <h1>Governing Law:</h1>
            <div className="mb-5">
              <p>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of Tamil Nadu, India. You agree to
                submit to the exclusive jurisdiction of the courts located in
                Tamil Nadu for the resolution of any disputes.
              </p>
            </div>

            <h1>Contact Us :</h1>
            <div className="mb-5">
              <p>
                If you have any questions about these Terms and Conditions,
                please contact us:
              </p>
              <p>By email: support@praathee.com</p>
              <p>By phone: 7092484157</p>
            </div>
          </div>
        </div>
      </div>
      </Container>
      <LetsGrow/>
      <Footer />
    </section>
  );
};

export default TermsandConditions;
