import React from "react";
import "./CareerOpportunities.css";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import softwareImage from '../../assests/career/softwareEngineering.svg'
import dataScienceImage from '../../assests/career/dataScience.svg'
import productManagementImage from '../../assests/career/productManagement.svg'
import uiuxImage from '../../assests/career/uiuxDesign.svg'

const CareerOpportunities = () => {
  const text1 = `With Praathee, get ready to discover and explore exciting career opportunities that await you. As a leading
     software development company, we offer a variety of roles such as,`;

  return (
    <>
      <Container>
        <div className="oppoText">
          <h1 style={{ color: " rgb(60, 112, 232)", fontSize: '26px', marginBottom: '25px' }}>Career Opportunities</h1>
          <p style={{fontSize: '19px', marginBottom: '25px'}}>{text1}</p>
        </div>

        <Row>
          <Col style={{marginBottom: '25px'}} className="d-flex justify-content-center">
            {/* <div className="careerCard"> */}
              <Card className="careerStyle">
                <Card.Img alt="software engineering" variant="top" src={softwareImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{fontSize: '26px'}}>Software Engineering</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col style={{marginBottom: '25px'}} className="d-flex justify-content-center">
            {/* <div className="careerCard"> */}
              <Card className="careerStyle">
                <Card.Img alt="data science" variant="top" src={dataScienceImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{fontSize: '26px'}}>Data Science</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col style={{marginBottom: '25px'}} className="d-flex justify-content-center">
            {/* <div className="careerCard"> */}
              <Card className="careerStyle">
                <Card.Img alt="product management" variant="top" src={productManagementImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{fontSize: '26px'}}>Product Management</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col style={{marginBottom: '25px'}} className="d-flex justify-content-center">
            {/* <div className="careerCard"> */}
              <Card className="careerStyle">
                <Card.Img alt="ui/ux design" variant="top" src={uiuxImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{fontSize: '26px'}}>UI/UX Design</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CareerOpportunities;
