import React from "react";
import "./CareerForm.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import careerFormImage from "../../assests/career/careerFormImage.svg";
import { Button } from "react-bootstrap";

const CareerForm = () => {
  return (
    <>
      <Container style={{ marginTop: "80px" }}>
        <Row>
          <Col md={12} lg={7} className="d-none d-md-block">
            <img src={careerFormImage} style={{width: '80%', height: '100%'}} alt="career form" />
          </Col>

          <Col md={12} lg={5}>
            <h2 style={{ color: "rgb(60, 112, 232)" }}>Fill out the form</h2>
            <p style={{fontSize: '19px'}}>
              Begin your journey toward an exciting career At Praathee
              Technologies
            </p>

            <Form style={{ marginTop: "40px" }}>
              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Full Name</Form.Label>
                    <Form.Control type="email" placeholder="Enter Name" />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email Address"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Phone Number</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Phone Number"
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Location</Form.Label>
                    <Form.Control type="email" placeholder="Enter Location" />
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginBottom: "10px" }}>
                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Position Applied for</Form.Label>
                    <Form.Control type="email" placeholder="Enter Position" />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label style={{fontSize: '19px'}}>Resume</Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </Col>
              </Row>

              <Button className="careerFormBtn" variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CareerForm;
