import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import './LetsGrow.css'
import leftEllipse from '../../assests/home/footerStart.svg'
import rightEllipse1 from '../../assests/home/footerEnd1.svg'
import rightEllipse2 from '../../assests/home/footerEnd2.svg'
import { Link } from 'react-router-dom'

const LetsGrow = () => {
  return (
    <>
    <div style={{ position: 'relative'}}>
    <Container fluid className='letsGrowContainer'>
    
      
        <Container className='letsGrowContent'>

            <Row>
                <Col lg={8}>
                <p style={{ color: 'white', textAlign: 'center', fontWeight: '700', fontSize: '35px'}}>LET'S GROW YOUR BUSINESS TOGETHER</p>
                </Col>

                <Col lg={4} style={{display: 'flex', justifyContent: 'center'}}>
                <Link style={{textDecoration: 'none'}} to='/contact'><Button variant='light' size='lg' className='quotebtn'>Get a quote</Button></Link>
                </Col>
            </Row>
        </Container>

    </Container>

    <img src={leftEllipse} alt="" style={{position: 'absolute', top: '30%', width: '32px'}}/>
    <img src={rightEllipse1} alt="" style={{position: 'absolute', top: '-10%',right: '0px',  width: '57px'}}/>
    <img src={rightEllipse2} alt="" style={{position: 'absolute', top: '42%',right: '26px', width: '30px'}}/>
    </div>
    </>
  )
}

export default LetsGrow