import React from "react";
import { Card, Row } from "react-bootstrap";
import planningImage from '../../../assests/services/MobileAppDevelopment/planningImage.svg'
import brainStormImage from '../../../assests/services/MobileAppDevelopment/brainstormingImage.svg'
import designImage from '../../../assests/services/MobileAppDevelopment/designImage.svg'
import developmentImage from '../../../assests/services/MobileAppDevelopment/developmentImage.svg'
import testingImage from '../../../assests/services/MobileAppDevelopment/testingImage.svg'
import maintenanceImage from '../../../assests/services/MobileAppDevelopment/maintenanceImage.svg'
import './whatMakesCard.css'

const WhatMakesCard = () => {
  const cardData = [
    {
      id: 1,
      title: "PLANNING & UNDERSTANDING",
      description:
        "Your needs and the scope of the app will be discussed and analyzed.",
      img: planningImage,
      alt: 'PLANNING & UNDERSTANDING'
    },
    {
      id: 2,
      title: "BRAINSTORMING & ANALYSIS",
      description:
        "App features and tools that are to be used will be confirmed.",
        img: brainStormImage,
        alt: 'BRAINSTORMING & ANALYSIS'
    },
    {
      id: 3,
      title: "DESIGNING",
      description:
        "Development of user interface and user experiences will be in process.",
        img: designImage,
        alt: 'DESIGNING'
    },
    {
      id: 4,
      title: "DEVELOPMENT",
      description:
        "App Coding, Backend infrastructure, and Integrating third-party APIs and services will take place.",
        img: developmentImage,
        alt: 'DEVELOPMENT'
    },
    {
      id: 5,
      title: "TESTING",
      description:
        "Bugs and errors will be fixed, ensuring the quality of the app.",
        img: testingImage,
        alt: 'TESTING'
    },
    {
      id: 6,
      title: "MAINTENANCE & SERVICES",
      description:
        "New updates, New features, and Bug fixation will be done accordingly.",
        img: maintenanceImage,
        alt: 'MAINTENANCE & SERVICES'
    },
  ];

  return (
    <>
      <div>
        <Row className="webCard1" style={{gap: '21px'}}>
          {cardData.map((card) => (
            <Card style={{ width: "324px", marginBottom: '60px', height: '216px'  }} className='hoverCard' key={card.id}>
              <Card.Img alt={card.alt} variant='top' src={card.img} style={{width: '50px', marginTop: '10px', height: '50px', marginLeft: 'auto', marginRight: 'auto'}}/>
              <Card.Body>
                <Card.Title as="h3" style={{fontSize: '20px', fontWeight: 'bold', textAlign: 'center'}}>{card.title}</Card.Title>
                
                <Card.Text style={{fontSize: '19px', fontWeight: 300, lineHeight: '22px', textAlign: 'center'}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row>

        {/* <Row className="webCard2" style={{gap: '21px'}}>
          {cardData.slice(3, 6).map((card) => (
            <Card style={{ width: "324px", marginBottom: '60px', height: '216px' }} className='hoverCard' key={card.id}>
              <Card.Img alt={card.alt} variant="top" src={card.img} style={{width: '50px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto'}}/>
              <Card.Body>
                <Card.Title as="h3" style={{fontSize: '20px', fontWeight: 'bold', textAlign: 'center'}}>{card.title}</Card.Title>
                
                <Card.Text style={{fontSize: '19px', fontWeight: 300, lineHeight: '22px', textAlign: 'center'}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row> */}
      </div>
    </>
  );
};

export default WhatMakesCard;
