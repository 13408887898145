import React from 'react'
import './OurServices.css'
import OurServicesCard from './OurServicesCard'

const OurServices = () => {
  return (
    <>
    
    <h3 className='heading'>OUR SERVICES</h3>
    <p style={{fontSize: '26px', textAlign: 'center', fontWeight: 300}}>We Provide Best IT Solutions For 7+ Years</p>
    <OurServicesCard/>
    </>
  )
}

export default OurServices