import React from "react";
// import "./BestMobileApp.css";
import { Col, Container, Row } from "react-bootstrap";
import bestMobileImage from "../../../assests/services/MobileAppDevelopment/bestMobileImage.svg";

const BestMobileApp = () => {
  const text1 = `With years of expertise and pride in delivering 300+ successful software
     solutions, Praathee Technologies is the best at designing and developing
      brilliant mobile applications.`;

  const text2 = `From developing smartphone applications to designing custom-made digital
       assistants, we strive to provide easy customization and well-developed
        services. Besides that, be it any form of Mobile app services, we use tools
         that are updated and of exceptional value. I.e, We use Swift for IOS and Java
          for Android.`;

  return (
    <>
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <h1 style={{ fontWeight: 500, fontSize: "26px"}}>
            Innovative <span style={{color: '#0073CF'}}>Mobile App</span> Development Solutions for Your Business
            </h1>
            <p
              style={{
                fontWeight: 300,
                fontSize: "19px",
              }}
            >
              {text1}
            </p>
            <p
              style={{
                fontWeight: 300,
                fontSize: "19px",
              }}
            >
              {text2}
            </p>
          </Col>

          <Col lg={5} md={12} className="d-none d-md-block">
            <img src={bestMobileImage} alt="mobile app development company" style={{width: '100%', height: '100%'}} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BestMobileApp;
