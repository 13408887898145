import React from 'react'
import './WebBanner.js'
import webDevImg from '../../../assests/Banner/webDevBanner.svg'
import './WebBanner.css'

const WebBanner = () => {
  const text1= `Create, Develop, and Connect World-Wide With Custom-based
   Web Applications Services Right Away`
  return (
    <>
<div className="banner">
      <img src={webDevImg} alt="web application development" className='Webbanner-image' />
      <div className="banner-text">
        <h1 style={{fontWeight: 700, fontSize: '40px'}}>WEB APPLICATION DEVELOPMENT</h1>
        <p style={{fontWeight: 400, fontSize: '30px', whiteSpace: 'pre-line'}}>{text1}</p>
      </div>
    </div>
    </>
  )
}

export default WebBanner