import React from 'react'
import './CareerBanner.css'
import bannerImage from '../../assests/Banner/careerBanner.svg'


const CareerBanner = () => {
  return (
    <>
        <div className="Careerbanner">
      <img src={bannerImage} alt="career" className='Careerbanner-image' />
      <div className="Careerbanner-text">
        <h2 style={{fontSize: '44px'}}>CAREERS</h2>
        <p style={{fontSize: '30px'}}>Your Ideas Are Our Assests - Join Our Team And Make Success Possible</p>
      </div>
    </div>
    </>
  )
}

export default CareerBanner