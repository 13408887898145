import React from "react";
import "./UnifiedWebsite.css";
import { Col, Container, Row } from "react-bootstrap";
import unifiedImg from "../../../assests/services/WebDevelopment/unifiedWebsite.svg";

const UnifiedWebsite = () => {
  return (
    <>
      <Container>
        <Row>
          <Col sm={12} md={4} lg={6}>
            <h2 className="mb-4" style={{ fontSize: '26px', fontWeight: 500}}>
              Praathee Technologies - A Unified Web App Developer With{" "}
              <span style={{ color: "rgb(60, 112, 232)"}}>
                Expert Solutions
              </span>
            </h2>

            <p className="mb-4" style={{ fontWeight: 300, fontSize: '19px'}}>
              As a valued Web application development service provider, Praathee
              Technologies stands the best in all areas of expertise, especially
              in creating enterprise Web apps, Web portals customer-facing apps,
              etc. At our company, we promptly offer clients comprehensive web
              app services at competitive prices.
            </p>

            <p style={{ fontWeight: 300, fontSize: '19px'}}>
              While this itself is a plus, our expert team is equipped with
              external web knowledge and tools with a simplified user
              interface. So, from creating simple custom-made web app services
              to complicated enterprise software solutions we provide
              everything. 
            </p>
          </Col>

          <Col md={4} lg={6} className="d-none d-md-block">
            <img
              style={{ width: '100%', height: '100%' }}
              src={unifiedImg}
              alt="web application development company"
              
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UnifiedWebsite;
