import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebook } from "react-icons/fa";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io5";
import praatheeLogo from '../../assests/Full white logo 1.svg'
import { Link } from "react-router-dom";

const Footer = () => {
  
  const address = ` No.11/4 POOJA GARDEN,
                    Kalapatti Main Rd, Indira Nagar,
                    Civil Aerodrome Post, Coimbatore,
                    Tamil Nadu, India - 641014`;

  const text1 =`                Innovating for tomorrow's needs,
   we craft next-gen software
                solutions with our customers in
                 mind`

                 const text2 =`Follow us on social media to
                  stay connected`

  return (
    <>
      <Container fluid className="footer-container">
        <Container className="footer-content">
          <Row style={{ marginBottom: "60px" }}>
            <Col lg={4}>
              {/* <h3 style={{ color: "white" }} className="mb-4">
                Praathee Image
              </h3> */}
              <img src={praatheeLogo} alt="praathee logo" className="mb-4" />
              <p style={{ color: "white", whiteSpace: 'pre-line' }}>
                      {text1}
              </p>
              <div className="social-link mt-30">
                <h5 style={{ color: "rgb(65, 133, 207)", whiteSpace: 'pre-line' }}>
                  {text2}
                </h5>

                <a
                  href="https://www.facebook.com/Praathee?mibextid=YMEMSu"
                  style={{
                    borderRadius: "50%",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "32px",
                    height: "34px",
                    marginRight: "10px",
                  }}
                >
                  <FaFacebook
                    style={{
                      fontSize: "20px",
                      color: "#3b5998",
                      margin: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/praathee-media-private-limited/mycompany/?viewAsMember=true"
                  style={{
                    borderRadius: "50%",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "32px",
                    height: "34px",
                  }}
                >
                  <TiSocialLinkedinCircular
                    style={{
                      fontSize: "24px",
                      color: "#0077B5",
                      margin: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@PraatheeMedia"
                  style={{
                    borderRadius: "50%",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "32px",
                    height: "34px",
                    marginRight: "10px",
                  }}
                >
                  <TiSocialYoutube
                    style={{
                      fontSize: "20px",
                      color: "#3b5998",
                      margin: "0px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/praathee_media?igsh=cXk3azRjOTd4b3ln&utm_source=qr"
                  style={{
                    borderRadius: "50%",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "32px",
                    height: "34px",
                  }}
                >
                  <IoLogoInstagram
                    style={{
                      fontSize: "20px",
                      color: "#0077B5",
                      margin: "0px",
                    }}
                  />
                </a>
              </div>
            </Col>

            <Col style={{marginBottom: '20px'}} >
              <h3 className="mb-4" style={{ color: "rgb(65, 133, 207)" }}>
                Company
              </h3>
              <div style={{ color: "white", lineHeight: '28px', fontSize: '19px' }} >
                {/* <p>About Us</p> */}
                <Link style={{textDecoration: 'none', color: 'white', marginBottom: '25px'}} to="/about">About Us</Link>
                <br />
                <Link style={{textDecoration: 'none', color: 'white'}} to="/mobile-app-development-service">Services</Link>
                <br />
                <Link style={{textDecoration: 'none', color: 'white'}} to="/contact">Contact Us</Link>
                <br />
                <Link style={{textDecoration: 'none', color: 'white'}} to="/career">Career</Link>
                <br />
                <Link style={{textDecoration: 'none', color: 'white'}} to="/privacy-policy">Privacy Policy</Link>
                <br />
                <Link style={{textDecoration: 'none', color: 'white'}} to="/terms-and-conditions">Terms and Conditions</Link>
                {/* <p>Services</p>
                <p>Contact Us</p>
                <p>Career</p> */}
              </div>
            </Col>

            <Col>
              <h3 className="mb-4" style={{ color: "rgb(65, 133, 207)" }}>
                Stay Connected
              </h3>
              <p style={{ color: "rgb(65, 133, 207)", fontSize: '26px' }}>Join with our team</p>
              <a href={'mailto:hr@praathee.com'} style={{ color: "white", textDecoration: 'none', fontSize: '19px'}}>
                hr@praathee.com
              </a>
              <p style={{ color: "rgb(65, 133, 207)", marginTop: '13px', fontSize: '26px' }}>Sales</p>
              <a href={'mailto:sales@praathee.com'} style={{ color: "white", textDecoration: 'none', fontSize: '19px' }}>sales@praathee.com</a>
              <p style={{ color: "white", marginTop: '10px' }}>+91 97913 95647</p>
            </Col>

            <Col>
              <h3 className="mb-4" style={{ color: "rgb(65, 133, 207)" }}>
                Contact Us
              </h3>
              <div style={{ color: "white" }}>
                <div style={{ whiteSpace: "pre-line" }}>
                  <p>{address}</p>
                </div>

                {/* <p>+91 7092484157</p> */}
                <a href={'mailto:support@praathee.com'} style={{ color: "white", textDecoration: 'none', fontSize: '19px' }}>support@praathee.com</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 style={{ color: "white", textAlign: "center" }}>
                © 2024 Praathee Technologies Pvt Ltd
              </h6>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Footer;
