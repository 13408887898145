import React from "react";
import "./ClientReview.css";
import { Col, Container, Row } from "react-bootstrap";
// import empImage from "../assests/empower.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "react-bootstrap/Card";

const ClientReviews = () => {
  const text1 = `With Exceptional Ideas, We Always Strive To Break Down Complex
                Problems Into A Simple Solution. So, Whatever Your Needs Are, You
                 Can Reach Us And Leave The Rest Assured`;

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Container fluid className="clientContainer">
      <Container>
        <Row>
          <Col className="col1">
            <div className="clientContent">
              <h4 className="mb-4">Our Client Reviews-</h4>
              <h3 className="mb-4">
                1k+ Clients Liked Our Software Development Service
              </h3>
              <p style={{ fontSize: "19px", whiteSpace: "pre-line" }}>
                {text1}
              </p>
            </div>
          </Col>

          <Col md={12} lg={6} className="col2">
            <div className="slider-wrapper">
              <Slider {...settings}>
                <div>
                  <Card
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <Card.Title
                        as="h3"
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                          marginTop: "-10px",
                          fontSize: "26px",
                          paddingTop: "10px",
                        }}
                      >
                        ANS Solutions
                      </Card.Title>

                      <Card.Text style={{ fontSize: "17px" }}>
                        Partnering with Prathee, for our software development
                        projects has been nothing short of a game-changer. Their
                        professionalism, attention to detail, and commitment to
                        delivering top-quality solutions have consistently
                        exceeded our expectations. Every project we have
                        collaborated on has been met with unparalleled
                        expertise. Their team is incredibly responsive,
                        adaptable, and efficient, making the entire development
                        process seamless. We highly value our partnership with
                        Prathee, and look forward to many more successful
                        collaborations in the future.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="slick-slide-center">
                  <Card
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  >
                    <Card.Body>
                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                          marginTop: "-10px",
                          fontSize: '26px'
                        }}
                      >
                        NM Engineering 

                      </Card.Title>

                      <Card.Text >
                        The team’s expertise in web application development was
                        outstanding. They listened closely to our requirements,
                        delivering a user-friendly interface that exceeded our
                        expectations. Their attention to detail and timely
                        delivery were impressive. Highly recommend them to
                        anyone looking for reliable web development services!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                {/* <div className="slick-slide-center">
                  <Card
                    style={{
                      width: "100%",
                      height: "13rem",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  >
                    <Card.Body style={{ height: "250%" }}>
                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                          marginTop: "-10px",
                        }}
                      >
                        Card Title
                      </Card.Title>

                      <Card.Text>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Totam ducimus vitae a perferendis quae eligendi
                        eveniet consectetur fugit unde, officia suscipit rem
                        cupiditate, nihil possimus porro placeat itaque! Enim,
                        quam!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="slick-slide-center">
                  <Card
                    style={{
                      width: "100%",
                      height: "13rem",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  >
                    <Card.Body style={{ height: "250%" }}>
                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                          marginTop: "-10px",
                        }}
                      >
                        Card Title
                      </Card.Title>

                      <Card.Text>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Totam ducimus vitae a perferendis quae eligendi
                        eveniet consectetur fugit unde, officia suscipit rem
                        cupiditate, nihil possimus porro placeat itaque! Enim,
                        quam!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="slick-slide-center">
                  <Card
                    style={{
                      width: "100%",
                      height: "13rem",
                      borderRadius: "20px",
                      border: "none",
                    }}
                  >
                    <Card.Body style={{ height: "250%" }}>
                      <Card.Title
                        style={{
                          textAlign: "center",
                          marginBottom: "15px",
                          marginTop: "-10px",
                        }}
                      >
                        Card Title
                      </Card.Title>

                      <Card.Text>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Totam ducimus vitae a perferendis quae eligendi
                        eveniet consectetur fugit unde, officia suscipit rem
                        cupiditate, nihil possimus porro placeat itaque! Enim,
                        quam!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div> */}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ClientReviews;
