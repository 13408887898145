import React from 'react'
import CloudBanner from './Banner/CloudBanner'
import GetInTouch from './GetinTouch/GetInTouch'
import PrPower from './Power/PrPower'
import LetsGrow from '../../Home/LetsGrow/LetsGrow'
import Footer from '../../Common/Footer/Footer'
import TopNav from '../../Common/Navbar/TopNav'

const CloudApp = () => {
  return (
    <>
    <TopNav/>
    <CloudBanner/>
    <GetInTouch/>
    <PrPower/>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default CloudApp