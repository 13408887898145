import React from "react";
import { Container } from "react-bootstrap";
import "./OurServicesCard.css";
import Card from "react-bootstrap/Card";
import blockChainImage from '../../assests/home/Blockchain Technology.svg'
import mobileDevelopmentImage from '../../assests/home/Google Mobile.svg' 
import cloudImage from '../../assests/home/Cloud Development.svg'
import webImage from '../../assests/home/Web.svg'

const OurServicesCard = () => {
  return (
    <>
      <Container
        fluid
        className="cardsContainer"
        style={{ padding: 50, marginBottom: 20 }}
      >
        <div className="card-style">
          <div className="card1">
            <Card style={{ width: "18rem" }} className="indicards">
            <Card.Img variant="top" className="indiCardImg" alt="mobile app development" src={mobileDevelopmentImage}/>
              <Card.Body>
                <Card.Title style={{ marginBottom: 30}}>Mobile App Development</Card.Title>

                <Card.Text style={{ marginBottom: 60, fontSize: '19px'}}>
                    Robust, progressive and hybrid mobile app solutions that best fit for your need
                </Card.Text>
                <Card.Link href="/mobile-app-development-service">Read More</Card.Link>
              </Card.Body>
            </Card>
          </div>

          <div className="card2">
            <Card style={{ width: "18rem" }} className="indicards">
            <Card.Img variant="top" className="indiCardImg" alt="web app development" src={webImage}/>
              <Card.Body>
                <Card.Title style={{ marginBottom: 30}}>Web App Development</Card.Title>

                <Card.Text style={{ marginBottom: 33, fontSize: '19px'}}>
                    Build a well-constructed website and web application functionality and interactivity
                </Card.Text>
                <Card.Link href="/web-app-development-service">Read More</Card.Link>
              </Card.Body>
            </Card>
          </div>

          <div className="card3">
            <Card style={{ width: "18rem" }} className="indicards">
            <Card.Img variant="top" className="indiCardImg" alt="cloud services" src={cloudImage}/>
              <Card.Body>
                <Card.Title style={{ marginBottom: 25}}>Cloud Services</Card.Title>

                <Card.Text style={{ marginBottom: 40, fontSize: '19px'}}>
                    Exceptional real-time solutions & customized cloud solutions according to your specific requirements
                </Card.Text>
                <Card.Link >Read More</Card.Link>
              </Card.Body>
            </Card>
          </div>

          <div className="card4">
            <Card style={{ width: "18rem" }} className="indicards">
              <Card.Img variant="top" className="indiCardImg" alt="blockchain services" src={blockChainImage}/>
              <Card.Body>
                <Card.Title style={{ marginBottom: 25}}>Blockchain Services</Card.Title>

                <Card.Text style={{ marginBottom: 38, fontSize: '19px'}}>
                  Unlocking possibilities with blockchain solutions: Your path to innovation and security
                </Card.Text>
                <Card.Link>Read More</Card.Link>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  );
};

export default OurServicesCard;
