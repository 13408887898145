import React from 'react'
import CloudAppBanner from '../../../assests/services/CloudAppDevelopment/cloudBanner.svg'

const CloudBanner = () => {
  return (
    <div className="mobileBanner">
    <img src={CloudAppBanner} alt="" className='mobileBanner-image' />
    <div className="banner-text">
      <p style={{ fontWeight: 700, fontSize: '40px'}}>CLOUD APP DEVELOPMENT</p>
      <p style={{ fontWeight: 400, fontSize: '20px'}}>Your Ticket to Skyrocket Your Business With Re-Defined Technology</p>
    </div>
  </div>
  )
}

export default CloudBanner