import React from "react";
import { Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./AndroidAppDev.css";

const AndroidAppDev = () => {
  var settings = {
    className: "custom-dots",
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const text1 = `Android is one of the most widely used mobile platforms, and ourteam is well-equipped to help businesses create, maintain, and
              revamp custom applications using tools like Java and Jetpack Compose. Our customer-friendly professionals, including UI/UX Designers,
               Mobile Application Strategists, Developers, and Testers, work collaboratively to ensure your app stands out. With our expertise, you can
                achieve innovative and user-centric Android mobile applications that drive profitability effortlessly.`;

  const text2 = `At Praathee Technologies, we prioritize our customer's needs and deliver high-quality products on time. Our commitment to excellence has
   made us one of the best iOS app development companies in the industry. Whether you need an app for iPad, iPhone, Apple TV, or Apple Watch, we have the expertise to build applications that perform at their utmost potential. Choose us for your iOS development needs, and experience the difference that highly qualified results can make.`;
  return (
    <>
      <Container style={{ marginTop: "80px", marginBottom: "120px" }}>
        <Slider {...settings}>
          <div>
            <h2
              style={{
                color: "#0073CF",
                fontWeight: 500,
                fontSize: "26px",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              Android Application Development Services
            </h2>
            <p
              style={{
                // textAlign: "center",
                fontWeight: 300,
                fontSize: "19px",
                // lineHeight: "20.83px",
                // whiteSpace: "pre-line",
              }}
            >
              {text1}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "#0073CF",
                fontWeight: 500,
                fontSize: "26px",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              IOS Application Development Services
            </h2>

            <p
              style={{
                // textAlign: "center",
                fontWeight: 300,
                fontSize: "19px",
                // lineHeight: "20.83px",
                // whiteSpace: "pre-line",
              }}
            >
              {text2}
            </p>
          </div>
        </Slider>
      </Container>
    </>
  );
};

export default AndroidAppDev;
