import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./Home/HomePage";
import AboutPage from "./About/AboutPage";
import CareerPage from "./Careers/CareerPage";
import ContactPage from "./Contact/ContactPage";
import WebApplication from "./Services/WebApplicationDevelopment/WebApplication";
import MobileAppDev from "./Services/MobileApplicationDevelopment/MobileAppDev";
import BlockChain from "./Services/BlockChainDevelopment/BlockChain";
import CloudApp from "./Services/CloudAppDevelopment/CloudApp";
import ScrollToTop from "./ScrollToTop";
import PrivacyPolicy from "./Privacy/PrivacyPolicy";
import TermsandConditions from "./TermsandConditions/TermsConditions";

const Routing = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/web-app-development-service"
            element={<WebApplication />}
          />
          <Route
            path="/mobile-app-development-service"
            element={<MobileAppDev />}
          />
          <Route path="/block-chain-development" element={<BlockChain />} />
          <Route path="/cloud-app-development" element={<CloudApp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms-and-conditions" element={<TermsandConditions/>}></Route>
        </Routes>
      </Router>
    </>
  );
};

export default Routing;
