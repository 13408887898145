import React from "react";
import mobileAppBanner from "../../../assests/Banner/mobileAppBanner.svg";
import "./mobileBanner.css";

const MobileBanner = () => {
  const text1 = `Trusted Mobile App Development Company With User-Friendly 
  Resources & Enhanced Services.`;
  return (
    <div className="mobileBanner">
      <img src={mobileAppBanner} alt="mobile app development" className="mobileBanner-image" />
      <div className="banner-text">
        <h2 style={{ fontWeight: 700, fontSize: "40px" }}>
          MOBILE APPLICATION DEVELOPMENT
        </h2>
        <p
          style={{ fontWeight: 400, fontSize: "30px", whiteSpace: "pre-line" }}
        >
          {text1}
        </p>
      </div>
    </div>
  );
};

export default MobileBanner;
