import React from 'react'
import { Container } from 'react-bootstrap'

const VersatileBlock = () => {
  return (
    <>
    <Container style={{marginTop: '80px'}}>
        <p style={{fontWeight: 500, fontSize: '24px', textAlign: 'center'}}>Versatile Blockchain Development Services We Offer</p>
        <p style={{fontWeight: 300, fontSize: '16px', lineHeight: '20.83px'}}>At Praathee Technologies, we strive to bring a sense of security and a blend of perfection with the solutions and services that we offer. Therefore, when you get blockchain development services from us, you will get options that will help create and build your tailored solutions efficiently, promoting multiple transparency and business growth</p>
    
        <p style={{fontWeight: 500, fontSize: '20px', textAlign: 'center', marginTop: '80px', marginBottom: '80px'}}>Ready to Raise Your Business Standards? <span style={{color: '#0073CF'}}>Let's Collaborate Now!</span></p>
    
    </Container>
    </>
  )
}

export default VersatileBlock