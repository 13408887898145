import React from "react";
import "./BenefitsCard.css";
import Card from "react-bootstrap/Card";
import { Col, Container, Row } from "react-bootstrap";
import fasterDevelopmentImage from '../../assests/aboutUs/fasterDevelopment.svg'
import customerSupportImage from '../../assests/aboutUs/Technical Support.svg'
import maintenaceImage from '../../assests/aboutUs/Service.svg'

const BenefitsCard = () => {
  return (
    <>
      <Container >
        <Row>
        
          <Col className="d-flex justify-content-center">
          <div className="benefits-card">
            <Card >
              <Card.Img className="benefitsImg" variant="top" alt="faster development" src={fasterDevelopmentImage} />
              <Card.Body>
                <Card.Title style={{fontSize: '26px'}}>Faster Development</Card.Title>
                <Card.Text style={{fontSize: '19px'}}>
                  Delivering products or software solutions with precision and
                  flexibility is our goal. The more commited we are, the better
                  the results are.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col className="d-flex justify-content-center">
          <div className="benefits-card">
            <Card >
            <Card.Img className="benefitsImg" variant="top" alt="customer support" src={customerSupportImage} />
              <Card.Body>
                <Card.Title style={{fontSize: '26px'}}>24x7 CUSTOMER SUPPORT</Card.Title>
                <Card.Text style={{fontSize: '19px'}}>
                    We stay open to our client's needs and provide support with undivided attention 24/7.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>

          <Col className="d-flex justify-content-center">
          <div className="benefits-card">
            <Card >
            <Card.Img className="benefitsImg" variant="top" alt="support & maintenance" src={maintenaceImage} />
              <Card.Body>
                <Card.Title style={{fontSize: '26px'}}>SUPPORT & MAINTENANCE</Card.Title>
                <Card.Text style={{fontSize: '19px'}}>
                We provide exceptional software development services using top-class equipment focusing on excellence.
                </Card.Text>
              </Card.Body>
            </Card>
            </div>
          </Col>
        
        </Row>
      </Container>
    </>
  );
};

export default BenefitsCard;
