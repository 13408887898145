import React from "react";
import TopNav from "../Common/Navbar/TopNav";
import LetsGrow from "../Home/LetsGrow/LetsGrow";
import Footer from "../Common/Footer/Footer";
import { Container } from "react-bootstrap";
import CompanyBest from "./CompanyBest/CompanyBest";
import AboutBanner from "./Banner/AboutBanner";
import WhyChoose from "./WhyChoose/WhyChoose";
import OurTeam from "./OurTeam/OurTeam";
import BenefitsCard from "./BenefitsCard/BenefitsCard";
import { Helmet } from "react-helmet";


const AboutPage = () => {
  return (
    <>
    <div>
      <Helmet>
      <meta
            title="About us"
            content="Praathee Media : Crafting Excellence in Software Solutions"
          />
          <meta
            name="description"
            content="Discover Praathee Media, a leader in software solutions, renowned for our commitment to excellence and innovation. Trust us to elevate your business to new heights."
          />
          <meta
            property="og:title"
            content="Praathee Media: Delivering Excellence in Software Solutions"
          />
          <meta
            property="og:description"
            content="Choose Praathee Media for unparalleled excellence in software solutions. With our proven track record, we're committed to delivering results that exceed expectations."
          />
          <meta
            name="keywords"
            content="about praathee, praathee media, software developmet, mobile, web,  app development , india, coimbatore, blockchain, devlopment, cloud app, software, it, company, "
          />
      </Helmet>
    </div>
      <TopNav />
      <AboutBanner />
      <Container>
        <p className="mb-5 fw-bold" style={{fontSize: '26px'}}>
          For Years, Praathee Technologies has been serving its clients with the
          best software solutions and is delivering top-notch products
          efficiently. From small businesses to leading software companies,
          everyone is getting their desired services and is gaining popularity
          seamlessly.
        </p>
      </Container>
      <CompanyBest />
      <BenefitsCard/>
      <WhyChoose />
      {/* <OurTeam /> */}

      <LetsGrow />
      <Footer />
    </>
  );
};

export default AboutPage;
