import React from 'react'
import './BenefitsOfJoining.css'
import { Card, Col, Container, Row } from 'react-bootstrap'
import competitiveImage from '../../assests/career/competitive.svg'
import comprehensiveImage from '../../assests/career/comprehensive.svg'
import professionalDevelopmentImage from '../../assests/career/professionalDevelopment.svg'
import supportiveImage from '../../assests/career/supportiveWork.svg'

const BenefitsOfJoining = () => {
  return (
    <>
    <Container>
        <div className='benefitsText'>
            <h2 style={{ color: 'rgb(60, 112, 232)', marginTop: '80px', fontSize: '26px', marginBottom: '25px'}}>Benefits Of Joining Our Company</h2>
            
        </div>
        <p style={{ marginBottom: '25px', fontSize: '19px'}}>Joining our company doesn't just stop with working on exciting projects. Instead, you can also enjoy multiple perks such as,</p>

        <Row>
        <Col className='d-flex justify-content-center'>
            {/* <div className="careerCard"> */}
              <Card className="benefitsStyle">
                <Card.Img alt='competitive compensation' className='benefitsImage' variant="top" src={competitiveImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{ fontSize: '26px'}}>Competitive compensation</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col className='d-flex justify-content-center'>
            {/* <div className="careerCard"> */}
              <Card className="benefitsStyle">
                <Card.Img alt='comprehensive benefits' className='benefitsImage' variant="top" src={comprehensiveImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{ fontSize: '26px'}}>Comprehensive benefits</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col className='d-flex justify-content-center'>
            {/* <div className="careerCard"> */}
              <Card className="benefitsStyle">
                <Card.Img alt='career growth opportunities' className='benefitsImage' variant="top" src={professionalDevelopmentImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{ fontSize: '26px'}}>Career growth opportunities</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>

          <Col className='d-flex justify-content-center'>
            {/* <div className="careerCard"> */}
              <Card className="benefitsStyle">
                <Card.Img alt='supportive work culture' className='benefitsImage' variant="top" src={supportiveImage} />
                <Card.Body>
                  <Card.Title as='h3' style={{ fontSize: '26px'}}>Supportive work culture</Card.Title>
                </Card.Body>
              </Card>
            {/* </div> */}
          </Col>
        </Row>

        <p style={{ marginTop: '25px', fontSize: '19px'}}>So, are you excited to join our team and make your career opportunity a splendid one? If yes, send your resume to <a href={`mailto:hr@praathee.com`}>hr@praathee.com</a>. If your profile and skills matches our requirements, our HR team will get in touch with you</p>

        
    </Container>
    </>
  )
}

export default BenefitsOfJoining