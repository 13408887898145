import React from "react";
import { Card, Row } from "react-bootstrap";
import customAppImage from '../../../assests/services/WebDevelopment/webAppDevImage.svg'
import ecommerceImage from '../../../assests/services/WebDevelopment/e-CommerceImage.svg'
import uiuxImage from '../../../assests/services/WebDevelopment/UIUXimage.svg'
import testingImage from '../../../assests/services/WebDevelopment/AppTestingImage.svg'
import maintenanceImage from '../../../assests/services/WebDevelopment/maintenanceImage.svg'
import seamlessImage from '../../../assests/services/WebDevelopment/seamlessImage.svg'
import './WebAppServices.css'

const WebServiceCard = () => {
  const cardData = [
    {
      id: 1,
      title: "CUSTOM APPLICATION DEVELOPMENT",
      description:
        "With custom app development, our skilled team designs and develops static or interactive web apps tailored to your needs, delivering results efficiently and on time.",
      img: customAppImage,
      alt: "CUSTOM APPLICATION DEVELOPMENT"
    },
    {
      id: 2,
      title: "E-COMMERCE",
      description:
        "Specify your business needs, and we'll develop large-scale e-commerce websites and B2B portals on a budget with top-notch resources and frameworks.",
        img: ecommerceImage,
        alt: "E-COMMERCE"
    },
    {
      id: 3,
      title: "CREATUVE UI & UX DESIGN",
      description:
        "Utilizing UI and UX design with our well-engineered designers, we excellently conceptualize your visuals thus making your user interface and experiences stunning.",
        img: uiuxImage,
        alt: "CREATUVE UI & UX DESIGN"
    },
    {
      id: 4,
      title: "APPLICATION TESTING AND QA",
      description:
        "Our team of experts doesnt stop with creating and designing web apps. Instead, once the development process is complete, a series of tests will be done to ensure the quality of the services 100%.",
        img: testingImage,
        alt: "APPLICATION TESTING AND QA"
    },
    {
      id: 5,
      title: "MAINTENANCE AND SUPPORT",
      description:
        "Unlike other services, We offer 24/7 Customer support and give upgrades even after the product is completed and delivered.",
        img: maintenanceImage,
        alt: "MAINTENANCE AND SUPPORT"
    },
    {
      id: 6,
      title: "SEAMLESS WEB API AND INTEGERATION",
      description:
        "We provide web solutions that serve as an excellent framework of regulations and protocols, ensuring sustainability seamlessly.",
        img: seamlessImage,
        alt: "SEAMLESS WEB API AND INTEGERATION"
    },
  ];

  return (
    <>
      <div>
        
        <Row className="webCard1">
          {cardData.map((card) => (
            <Card style={{ width: "24rem", marginBottom: '60px', height: 'auto' }} className="hoverCard" key={card.id}>
              <Card.Img variant='top' src={card.img} style={{width: '50px', marginTop: '10px', height: '50px'}}/>
              <Card.Body>
                <Card.Title as='h3' style={{fontSize: '26px', fontWeight: 600}}>{card.title}</Card.Title>
                <hr />
                <Card.Text style={{fontSize: '19px', fontWeight: 300}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row>

        {/* <Row className="webCard2">
          {cardData.slice(3, 6).map((card) => (
            <Card style={{ width: "24rem", marginBottom: '60px', height: 'auto' }} className="hoverCard" key={card.id}>
              <Card.Img variant="top" src={card.img} style={{width: '50px', marginTop: '10px'}}/>
              <Card.Body>
                <Card.Title as='h3' style={{fontSize: '26px', fontWeight: 600}}>{card.title}</Card.Title>
                <hr />
                <Card.Text style={{fontSize: '19px', fontWeight: 300}}>{card.description}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Row> */}
      </div>
    </>
  );
};

export default WebServiceCard;
