import React from 'react'
import blockChainBanner from '../../../assests/services/BlockChainDevelopment/blockChainBanner.svg'

const BlockChainBanner = () => {
  return (
    <div className="mobileBanner">
    <img src={blockChainBanner} alt="" className='mobileBanner-image' />
    <div className="banner-text">
      <p style={{ fontWeight: 700, fontSize: '40px'}}>BLOCKCHAIN TECHNOLOGY</p>
      <p style={{ fontWeight: 400, fontSize: '20px'}}>A Systematic Approach to Embark and Uplift Innovation, Security, and Unparallel Efficiency to Businesses.</p>
    </div>
  </div>
  )
}

export default BlockChainBanner