import React from "react";
import "./Circles.css";
import { Col, Container, Row } from "react-bootstrap";
import userIncrease from "../../assests/Images/CirclesImg/Project Manager.png";
import timerImage from "../../assests/Images/CirclesImg/Delivery Time.png";
import qualityImage from "../../assests/Images/CirclesImg/Good Quality.png";

const Circles = () => {
  return (
    <>
      <Container>
        
          <Row className="circleStyle">
            
            <Col lg={4} md={12} className="mobile-circle d-flex justify-content-center">
              <img src={userIncrease} alt="years of establishment" />
              <div
                className="flex-column"
                style={{
                  textAlign: "center",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <h3 className="head1">7+</h3>

                <p className="sub1" style={{textAlign: 'start'}}>Years of establishment</p>
              </div>
            </Col>
            
            <Col lg={4} md={12} className="mobile-circle d-flex justify-content-center">
              <img src={qualityImage} alt="support" />
              <div
                className="flex-column"
                style={{
                  textAlign: "center",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <h3 className="head3">24/7</h3>
                <p className="sub3">Support</p>
              </div>
            </Col>

            
            <Col lg={4} md={12} className="mobile-circle d-flex justify-content-center">
              <img src={timerImage} alt="successful projects" />
              <div
                className="flex-column"
                style={{
                  textAlign: "center",
                  marginLeft: "10px",
                  fontSize: "19px",
                }}
              >
                <h3 className="head2">100+</h3>
                <p className="sub2" style={{textAlign: 'start'}}>Successful Projects</p>
              </div>
            </Col>
          </Row>
        
      </Container>
    </>
  );
};

export default Circles;
