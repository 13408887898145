import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './WhatMakes.css'
import whatMakesImage from '../../assests/home/whatMakesImage.svg'
import { FaUserTie } from "react-icons/fa";
import { LuPackageOpen } from "react-icons/lu";
import { IoMdThumbsUp } from "react-icons/io";
import customMadeImage from '../../assests/home/customMadeImage.svg'
import consistentSoftwareImage from '../../assests/home/consistentSoftwareImage.svg'

const WhatMakes = () => {
  return (
    <Container style={{ marginTop: 80}}>
        <Row>
            <Col lg={6} md={12}>
            <h2 style={{fontSize: '26px'}}>What Makes Our Software Development Company <span style={{color: 'blue'}}>The Best Service Provider</span>?</h2>
            <p style={{fontSize: '19px', marginBottom: '25px', marginTop: '25px'}}>With Exceptional Ideas, We Always Strive To Break Down Complex Problems Into A Simple Solution. So, Whatever Your Needs Are, You Can Reach Us And Leave The Rest Assured.</p>
            <ul className='whatMakesli'>
                <li><FaUserTie  className='icons'/>Professionalism With Excellent Guidance</li>
                <li><img src={consistentSoftwareImage} className='icons' alt="" />Consistent Software Solutions</li>
                <li><LuPackageOpen className='icons'/>On-Time Delivery</li>
                <li><img src={customMadeImage} className='icons' alt="" />Custom-Made Designs</li>
                <li><IoMdThumbsUp className='icons'/>100% Quality Assurance</li>
            </ul>
            </Col>

            <Col className='whatcol d-none d-md-block text-end' lg={6} md={12}>
             <img src={whatMakesImage} alt="" className='software development service' />
            </Col>
        </Row>
    </Container>
  )
}

export default WhatMakes