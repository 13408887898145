import React from 'react'
import BlockChainBanner from './Banner/BlockChainBanner'
import TrustedSolution from './TrustedSolution/TrustedSol.js'
import VersatileBlock from './VersatileBlockChain/VersatileBlock.js'
import LetsGrow from '../../Home/LetsGrow/LetsGrow.js'
import Footer from '../../Common/Footer/Footer.js'
import TopNav from '../../Common/Navbar/TopNav.js'

const BlockChain = () => {
  return (
    <>
    <TopNav/>
    <BlockChainBanner/>
    <TrustedSolution/>
    <VersatileBlock/>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default BlockChain