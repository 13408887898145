import React from 'react'
import ContactBanner from './Banner/ContactBanner'
import Team from './Team/Team'
import ContactForm from './ContactForm/ContactForm'
import TopNav from '../Common/Navbar/TopNav'
import LetsGrow from '../Home/LetsGrow/LetsGrow'
import Footer from '../Common/Footer/Footer'
import { Helmet } from 'react-helmet'

const ContactPage = () => {
  return (
    <>
        <div>
      <Helmet>
      <meta
            title="Contact Us"
            content="Contact Praathee Media - Your Premier Software Solutions Partner"
          />
          <meta
            name="description"
            content="Get in touch with Praathee Media today for innovative and custom software solutions. Our team is ready to assist you with your technology needs."
          />
          <meta
            property="og:title"
            content="Reach Out to Praathee Media for Expert Software Assistance"
          />
          <meta
            property="og:description"
            content="Ready to elevate your business with innovative software solutions? Connect with us to explore bespoke software solutions to drive your business forward. "
          />
      </Helmet>
    </div>
    <TopNav/>
    <ContactBanner/>
    <Team/>
    <ContactForm/>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default ContactPage