import React, { useEffect, useState } from 'react'
import './Empower.css'
import { Button, Col, Container, Row } from 'react-bootstrap'
import empImage from '../../assests/home/empowerImage.svg'
import { Link } from 'react-router-dom'

const Empower = () => {


  const texts = [
    "MOBILE APPS",
    "WEB APPS",
    "ERP",
    "BLOCKCHAIN",
    "SEO"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [texts.length]);



  return (
    <>
    <Container style={{ marginTop: '80px'}}>
    <Row>
        <Col sm={12} md={12} lg={6}>
        <h2 className='titleStyle'>
          EMPOWERING
          YOUR BUSINESS ONLINE WITH
          <br />
          <span className='rotatingText'>  {texts[currentIndex]}</span>
        </h2>
        <div className='descriptionStyle' >
            "Unlock your business's full potential. Contact us today—our experts are just a call or email away!"
          </div>
          <Link style={{color: 'white', textDecoration: 'none'}} to='/mobile-application-development'><Button variant='primary'  style={{borderRadius: 20, marginBottom: 20, width: '129px', height: '51px'}}>Explore Now</Button></Link>
        </Col>

        <Col md={12} lg={6} className='d-none d-md-block'>
        <img 
        src={empImage} alt="software development"
        
        // style={{ width: '80%', maxWidth: '300px'}}
        />
        </Col>
    </Row>
    </Container>
    </>
  )
}

export default Empower