import React from "react";
import "./WhyChoose.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import whyChooseImage from "../../assests/aboutUs/whyChooseLeave.svg";

const WhyChoose = () => {
  return (
    <>
      <Container style={{ marginBottom: "80px" }}>
        <div style={{ textAlign: "center" }} className="mt-5 mb-5">
          <h1 className="whyheading">WHY CHOOSE US ?</h1>
          <p style={{ fontSize: "26px" }}>
            Qualified and Timely software solutions from Professionals are
            guaranteed
          </p>
        </div>

        <Row>
          <Col md={12} lg={6} className="d-none d-md-block">
            <img src={whyChooseImage} alt="why choose us" style={{width: '100%', height: '100%'}}  />
          </Col>

          <Col sm={12} md={12} lg={6}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How Best Does Your Company Help Improve Our IT Infrastructure?
                </Accordion.Header>
                <Accordion.Body style={{fontSize: '19px'}}>
                  as a leading software development company, we provide
                  specialized services in all areas of expertise. from
                  optimizing better perfomance to delivering products in the
                  said time, everything is prioritized. besides that, our
                  service focuses on every field. i.e, from startups to large
                  enterprises, we provide expert solutions with excellence and
                  professionalism. so, undoubtedly you can choose from our
                  services and succeed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  List The Security Measures That You Provide To Your Clients.
                </Accordion.Header>
                <Accordion.Body style={{fontSize: '19px'}}>
                  We give high priority to our client’s security. As every
                  detail is crucial, we follow a strict privacy policy through
                  this list of methods.
                  <ul style={{ marginTop: "15px" }}>
                    <li>Advanced encrypted methods</li>
                    <li>Robust firewall protection</li>
                    <li>Extensive security audits</li>
                    <li>Comprehensive employee training</li>
                    <li>Multi-layered approach</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How Do You Calculate Your Pricing Structure For Design And
                  Development Services?
                </Accordion.Header>
                <Accordion.Body style={{fontSize: '19px'}}>
                  Our pricing structure varies with the design and services that
                  you choose. While the pricing structure might differ they are
                  cost-friendly and packed within your budget. Besides that,
                  here you don’t need to pay anything extra. Pricing standards
                  are simple and of the range.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhyChoose;
