import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import trustedImage from '../../../assests/services/BlockChainDevelopment/trustedSolutionImage.svg'

const TrustedSolution = () => {

  const text1 = `With Praathee Technologies competitive and effective Blockchain development services, your goal of creating a susceptible and secure
   application is made possible easily`


   const text2 = `As the best software development company with exceptional services, We Praathee
    Technologies marks a significant position in Blockchain development.`

   const text3 = `With a better understanding of blockchain usage for businesses, our blockchain app
    developers help elevate your transparency, security, and potential to the next level.
     Besides that, we also offer versatile options specialized in custom-made blockchain
      applications, smart contract development decentralized development, etc. So, for all
       your blockchain development doubts and needs, schedule a meeting with us and gain
        solutions swiftly.`

   
  return (
    <>
    <Container style={{marginTop: '40px'}}>
      <p style={{ fontWeight: 300, fontSize: '16px', whiteSpace: 'pre-line', textAlign: 'center', marginBottom: '80px'}}>{text1}</p>

      <Row>
        <Col lg={6}>
        <p style={{fontWeight: 500, fontSize: '18px', lineHeight: '23.44px'}}><span style={{color: '#0073CF'}}>Praathee Technologies</span> - Your Trusted Solution to Create and Embark Successful Blockchain Development Services.</p>
        <p style={{fontWeight: 300, fontSize: '15px', lineHeight: '19.53px', whiteSpace: 'pre-line'}}>{text2}</p>
        <p style={{fontWeight: 300, fontSize: '15px', lineHeight: '19.53px', whiteSpace: 'pre-line'}}>{text3}</p>
        </Col>

        <Col>
        <img src={trustedImage} alt="" />
        </Col>
      </Row>

      <p style={{fontWeight: 500, fontSize: '24px', textAlign: 'center', marginTop: '70px'}}>Get Blockchain Development Solutions Instantly - <span style={{color: '#0073CF'}}>Connect Now!</span></p>
    </Container>
    </>
  )
}

export default TrustedSolution