import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import Routing from './Route';

function App() {
  return (
    <>
    {/* <TopNav/>
    <Empower/>
    <Clients/>
    <AboutUs/>
    <Circles/>
    <OurServices/>
    <WhatMakes/>
    <ClientReviews/>
    <OurBlogs/>
    <LetsGrow/>
    <Footer/> */}
    <Routing/>
    </>
  );
}

export default App;
