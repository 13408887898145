import React from 'react'
import WebBanner from './Banner/WebBanner'
import UnifiedWebsite from './UnifiedWebsite/UnifiedWebsite'
import WebAppServices from './WebAppServices/WebAppServices'
import TopNav from '../../Common/Navbar/TopNav'
import WhyChoose from './WhyChooseUs/WhyChoose'
import LetsGrow from '../../Home/LetsGrow/LetsGrow'
import Footer from '../../Common/Footer/Footer'
import { Helmet } from 'react-helmet'

const WebApplication = () => {
  return (
    <>
        <div>
      <Helmet>
      <meta
            title="Web App Development"
            content="Praathee Media: Premier Web Application Development Services"
          />
          <meta
            name="description"
            content="Elevate your online presence with Praathee Media's expert web app development services. Tailored solutions to meet your business needs and exceed expectations."
          />
          <meta
            property="og:title"
            content="Custom Web Application Solutions by Praathee Media"
          />
          <meta
            property="og:description"
            content="Transform your business strategies into reality with transformative web app development from Praathee Media. Custom, scalable, and impactful web apps await."
          />
          <meta
            name="keywords"
            content="web app development, web app developers, web app development company, web application development, web app development service"
          />
      </Helmet>
    </div>
    <TopNav/>
    <WebBanner/>
    <UnifiedWebsite/>
    <WebAppServices/>
    <WhyChoose/>
    <LetsGrow/>
    <Footer/>
    </>
  )
}

export default WebApplication