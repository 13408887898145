import React from "react";
import "./Team.css";
import { Col, Container, Row } from "react-bootstrap";
import phoneImage from "../../assests/contact/phone.svg";
import locationImage from "../../assests/contact/location.svg"

const Team = () => {

  const loc = `Innovspace Coworking
No. 11/4, Pooja Garden,
Kalapatti Main Rd, 
Civil Aerodrome Post,
Coimbatore, India - 641 014`

  return (
    <>
      <Container
        style={{ marginTop: "80px", marginBottom: "60px" }}
        className="imgContainer"
      >
        <Row className="justify-content-center">
          <Col sm={12} md={4} className="text-center">
            <div>
              <img src={phoneImage} alt="HR team" />
              <h2 style={{fontSize: '26px'}}>HR TEAM</h2>
              <p style={{fontSize: '19px'}}><strong>Mail ID</strong> - <a href={`mailto:hr@praathee.com`}>hr@praathee.com</a></p>
              {/* <p>Mobile - 89256{" "}54157</p> */}
            </div>
          </Col>

          <Col sm={12} md={4} className="text-center">
            <div>
              <img src={phoneImage} alt="sales team" />
              <h2 style={{fontSize: '26px'}}>SALES TEAM</h2>
              <p style={{fontSize: '19px'}}><strong>Mail ID</strong> - <a href={`mailto:sales@praathee.com`}>sales@praathee.com</a></p>
              <p style={{fontSize: '19px'}}>Mobile - 97913{" "}95647</p>
              
            </div>
          </Col>

          <Col sm={12} md={4} className="text-center">
            <div>
              <img src={locationImage} alt="location" />
              <h2 style={{fontSize: '26px'}}>LOCATION</h2>
              <span className="addtext">{loc}</span>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Team;
