import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "./Navbar.css";
import { NavDropdown, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import React from "react";
import officalLogo from "../../assests/praatheelogo.png";

function TopNav() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  

  return (
    <>
      <Navbar expand="lg" className="navMain bg-body-tertiary" sticky="top">
        <Container>
          <Navbar.Brand as={NavLink} to="/">
            <img
              src={officalLogo}
              alt="praathee logo"
              width="150"
              height="auto"
            />
          </Navbar.Brand>

          {/* <Navbar.Toggle aria-controls='offcanvasNavbar' onClick={handleShow}/> */}

          <Navbar.Collapse className="d-none d-lg-flex">
            <Nav className="mx-auto justify-content-center">
              <Nav.Link to="/" className="ms-5" as={NavLink}>
                Home
              </Nav.Link>
              <Nav.Link to="/about" className="ms-5" as={NavLink}>
                About
              </Nav.Link>

              <NavDropdown
                className="ms-5"
                id="navbarScrollingDropdown"
                title={
                  <span
                    style={{
                      color:
                        location.pathname === "/web-app-development-service" ||
                        location.pathname === "/mobile-app-development-service"
                          ? "#0000ff"
                          : "#000",
                    }}
                  >
                    Services
                  </span>
                }
              >
                <NavDropdown.Item
                  to="/mobile-app-development-service"
                  as={NavLink}
                >
                  Mobile Application Development
                </NavDropdown.Item>

                <NavDropdown.Item
                  to="/web-app-development-service"
                  as={NavLink}
                >
                  Web Application Development
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link to="#link" className="ms-5">
                Product
              </Nav.Link> */}
              <Nav.Link to="/career" className="ms-5" as={NavLink}>
                Career
              </Nav.Link>
              <Nav.Link to="/contact" className="ms-5" as={NavLink}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* </Navbar.Collapse> */}
          <div className="d-flex align-items-center">
            <Link style={{textDecoration: 'none'}} to='/contact'><Button
              variant="primary"
              className="rounded-pill ms-2 me-2"
              size="lg"
              style={{ width: "129px", height: "51px" }}
            >
              Get in Touch
            </Button></Link>
            <Navbar.Toggle
              className="navToggle"
              aria-controls="offcanvasNavbar"
              onClick={handleShow}
            />
          </div>
        </Container>
      </Navbar>

      <Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="start"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
        <Navbar.Brand as={NavLink} to="/">
            <img
              src={officalLogo}
              alt="officialLogo"
              width="150"
              height="auto"
            />
          </Navbar.Brand>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <NavLink to="/"  className="nav-link ms-5" activeClassName="active">
              Home
            </NavLink>

            <NavLink to="/about"  className="nav-link ms-5" activeClassName="active">
              About
            </NavLink>
            <NavDropdown
                className="ms-5"
                id="navbarScrollingDropdown"
                title={
                  <span
                    style={{
                      color:
                        location.pathname === "/web-app-development-service" ||
                        location.pathname === "/mobile-app-development-service"
                          ? "#0000ff"
                          : "#000",
                    }}
                  >
                    Services
                  </span>
                }
              >
                <NavDropdown.Item
                  to="/mobile-app-development-service"
                  as={NavLink}
                >
                  Mobile Application Development
                </NavDropdown.Item>

                <NavDropdown.Item
                  to="/web-app-development-service"
                  as={NavLink}
                >
                  Web Application Development
                </NavDropdown.Item>
              </NavDropdown>
            {/* <Nav.Link href="#link" className="ms-5">
              Product
            </Nav.Link> */}
            <NavLink to="/career" className="nav-link ms-5" activeClassName="active">
              Career
            </NavLink>
            <NavLink to="/contact" className="nav-link ms-5" activeClassName="active">
              Contact
            </NavLink>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default TopNav;
