import React from 'react'
import { Container } from 'react-bootstrap'
import WhatMakesCard from './WhatMakesCard'
import { Link } from 'react-router-dom'

const WhatMakes = () => {
  return (
    <>
    <Container>
        <h2 style={{fontWeight: 500, fontSize: '26px', textAlign: 'center',marginTop: '80px', marginBottom: '25px', color: '#0073CF'}}>What Makes Us The Best In Mobile App Development Services?</h2>
        <p style={{fontWeight: 300, fontSize: '19px', marginBottom: '60px'}}>Our expert mobile app development team and the resources that we use are some of the most important factors that make us the best among many. From creating mobile applications from scratch to upgrading existing apps, we focus on delivering only the best. For instance, our development process itself can assure you how excellent your result will be.</p>
    
        <WhatMakesCard/>

        <p style={{textAlign: 'center', fontWeight: 500, fontSize: '26px', marginBottom: '120px', marginTop: '20px'}}>Want Effective Mobile App Development Services? <Link style={{color: '#0073CF', textDecoration: 'none'}} to='/contact'>Connect Now!</Link></p>
    
    </Container>
    </>
  )
}

export default WhatMakes