import React from "react";
import "./WebAppServices.css";
import { Container } from "react-bootstrap";
import WebServiceCard from "./WebServiceCard";

const WebAppServices = () => {
  return (
    <>
      <Container>
        <div className="webServiceText">
          <h2 style={{ color: "rgb(60, 112, 232)", fontWeight: 500, fontSize: '26px' }}>
            Web Application Development Services We Offer
          </h2>
          <p style={{ fontWeight: 400, fontSize: '22px'}}>
            Harness the growth of your business potential with tailor-made web
            frameworks and elevate your success effectively.
          </p>
        </div>

        <WebServiceCard/>
      </Container>
    </>
  );
};

export default WebAppServices;
